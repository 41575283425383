import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { I18N } from 'aurelia-i18n';
import { Store, connectTo } from 'aurelia-store';
import { State } from 'bsv-wasm-web';
import { Observable } from "rxjs";

  
@autoinject
export class AssetsHome {
  private tabs = 0;
  private fakeAssets = [{
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test1", profilePic: "img/bank_white.png", paymail: "alice@mybanka.eu"},
    name: "Christmas Doggies #1/999",
    description: "Lorem Ipsum",
    collection: "Christmas Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test2", profilePic: "img/bank_white.png", paymail: "bob@mybanka.eu"},
    name: "Christmas Doggies #2/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
  {
    owner: {displayName: "Test3", profilePic: "img/bank_white.png", paymail: "ben@mybanka.eu"},
    name: "Christmas Doggies #888/999",
    description: "Lorem Ipsum",
    collection: "Christmast Doggies Dec 2023",
    assetPic: "https://cdn.discordapp.com/attachments/1155848483950039091/1165968513081561189/OIG.png?ex=6548c7cc&is=653652cc&hm=9e218468c2fc66cc07df202d988ebd5be8cd722920ec74bfbfa3a4988be902b4&"
  },
]

private fakeCollectionList = [{
  name: "Collection 1",
  creator: "MyBanka",
  items: this.fakeAssets
}, {
  name: "Collection 2",
  creator: "Pikmin",
  items: this.fakeAssets
}, {
  name: "Collection 3",
  creator: "Jeff Bezos",
  items: this.fakeAssets
}, {
  name: "Collection 4",
  creator: "Random42",
  items: this.fakeAssets
}, {
  name: "Collection 5",
  creator: "JFC",
  items: this.fakeAssets
}]
  
  constructor(private i18n: I18N, private store: Store<State>, private router: Router) {
 
  } 

  // bind() {
  //   if ()
  // }
}
