// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/heart_empty.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./assets.scss"></require>
  <require from="../../components/header-titles/header-title-assets/header-title-assets"></require>  
  <section id="assets" class="flex-column">
    <header-title-assets tabs.two-way="tabs"></header-title-assets>
    <!--Code for a few collection-->

    <div class="collections_list flex-column" if.bind="tabs == 0" repeat.for="collection of fakeCollectionList">
      <div class="collection_creator flex-row">
        <h5>\${collection.name}</h5>
        <p>by</p>
        <p class="creator">\${collection.creator}</p>
      </div>
      <div class="item_list_container flex-row">
        <div class="item flex-column" repeat.for="item of collection.items">
          <div class="img_heart container flex-column">
            <div class="bg_heart flex-row">
              <img src="${___HTML_LOADER_IMPORT_0___}">
              <p>100</p>
            </div>
            <img id="item" src.bind= "item.assetPic">
          </div>
          <div class="info flex-column">
            <!-- <span class="info number">#1</span> -->
            <span class="info name">\${item.name}</span>
            <div class="price flex-column">
              <!-- <img class="currency" src="/static/img/bsv.svg"> -->
              <!-- <p class="price">Owned by: </p> -->
              <p class="price">\${iteù.description}</p>
              <p class="price">Collection: \${item.collection}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--Code close to what's needed when multiple collections-->

    <!-- <div class="collection_container flex-column" if.bind="tabs == 0" repeat.for="collection of fakeCollectionList">
      <div class="collection_creator flex-row">
        <h5>\${collection.name}</h5>
        <p>by</p>
        <p class="creator">\${collection.creator}</p>
      </div> <!--Repeat for collection + class.bind selected?--
      <div class="items_list_container flex-row">
        <div class="item_container" repeat.for="item of collection.items.slice(0, 10)">
          <div class="items flex-column">
            <img class="square" src.bind="item.assetPic">
            <div class="info flex-column">
              <div class="flex-grow"></div>
              <h4 class="title">\${item.name}</h4>
              <div class="price flex-row">
                <p class="price">Price</p>
                <p class="currency">Currency</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item_container" if.bind="collection.items.length > 10"> <!--click.delegate to collection_id--
          <div class="items flex-column">
            <img class="square" src.bind="collection.items[0].assetPic">
            <div class="info flex-column">
              <div class="flex-grow"></div>
              <h4 class="title">See more!</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="collections_list wrap" if.bind="tabs == 1">
      <div class="item flex-column" repeat.for="asset of fakeAssets">
        <div class="img_heart container flex-column">
          <div class="bg_heart flex-row">
            <img src="${___HTML_LOADER_IMPORT_0___}">
            <p>100</p>
          </div>
          <img id="item" src.bind= "asset.assetPic">
        </div>
        <div class="info flex-column">
          <!-- <span class="info number">#1</span> -->
          <span class="info name">\${asset.name}</span>
          <div class="price flex-column">
            <!-- <img class="currency" src="/static/img/bsv.svg"> -->
            <!-- <p class="price">Owned by: </p> -->
            <p class="price">\${asset.description}</p>
            <p class="price">Collection: \${asset.collection}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;